<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="cardTitle py-4"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex">
            <img
              src="@/assets/DonationManagement/DonationTitleLogo.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-7 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleAddEditViewDonationModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-8 pb-0">
          <v-form ref="donationForm" lazy-validation>
            <v-row
              v-if="this.type === 'edit' && this.mode === 'ONLINE'"
              class="px-5"
              justify="center"
            >
              <v-col class="pa-2" cols="6">
                <div class="formTitle">Donor Name</div>
                <div class="formValue">
                  {{ onlineDonationDetail.donor_name }}
                </div>
              </v-col>
              <v-col class="pa-2 pl-3" cols="6">
                <div class="formTitle ml-2">Donor Email</div>
                <div class="formValue ml-2">
                  {{ onlineDonationDetail.donor_email }}
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="this.type === 'edit' && this.mode === 'ONLINE'"
              class="px-5"
              justify="center"
            >
              <v-col class="pa-2" cols="6">
                <div class="formTitle">Donation Amount</div>
                <div class="formValue">
                  <v-icon color="#067605">mdi-currency-usd</v-icon>
                  {{ onlineDonationDetail.donated_amount }}
                </div>
              </v-col>
              <v-col class="pa-2 pl-3" cols="6">
                <div class="formTitle ml-2">Donation Date</div>
                <div class="formValue ml-2">
                  <v-icon>mdi-calendar-range</v-icon>
                  {{ onlineDonationDetail.donation_date_str }}
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="this.type === 'edit' && this.mode === 'ONLINE'"
              class="px-5"
              justify="center"
            >
              <v-col class="pa-2" cols="6">
                <div class="formTitle">Transuctions ID</div>
                <div class="formValue">
                  {{ onlineDonationDetail.transaction_id }}
                </div>
              </v-col>
              <v-col class="pa-2 pl-3" cols="6">
                <div class="formTitle ml-2">Message by donor</div>
                <div class="formValue ml-2">
                  {{ onlineDonationDetail.donor_special_message }}
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="
                this.type === 'add' ||
                (this.type === 'edit' &&
                  (this.mode === 'CASH' || this.mode === 'CHECK'))
              "
            >
              <v-col cols="12" class="px-6">
                <div class="ml-2">Mode of donation</div>
                <v-radio-group
                  class="radio-group pt-0 px-1"
                  small
                  v-model="payment_mode"
                  row
                >
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Cash"
                    value="Cash"
                  ></v-radio>
                  <v-radio
                    :disabled="formLoading"
                    color="#2C1963"
                    label="Check"
                    value="Check"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div
              v-if="this.type === 'edit' && this.mode === 'ONLINE'"
              style="margin-top: 35px"
            ></div>
            <!--donation ui for other panels-->
            <v-row no-gutters>
              <v-col class="px-4" cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="donationFor"
                  :items="donationForConditionalList"
                  label="Donation For"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-if="donationFor === 'school'" class="px-4" cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="school"
                  :items="schoolList"
                  item-text="school_name"
                  item-value="id"
                  label="Select School"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-if="donationFor === 'teacher'" class="px-4" cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="teacher"
                  :items="teacherList"
                  item-text="display_name"
                  item-value="id"
                  label="Select teacher"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-if="donationFor === 'student'" class="px-4" cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="student"
                  :items="studentList"
                  :item-text="getFieldText"
                  item-value="id"
                  label="Select student"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col v-if="donationFor === 'district'" class="px-4" cols="6">
                <v-autocomplete
                  outlined
                  dense
                  v-model="district"
                  :items="districtList"
                  item-text="district_name"
                  item-value="id"
                  label="Select District"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!--end donation ui for other panels-->
            <v-row
              no-gutters
              v-if="
                this.type === 'add' ||
                (this.type === 'edit' &&
                  (this.mode === 'CASH' || this.mode === 'CHECK'))
              "
            >
              <v-col class="px-4" cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="donorName"
                  label="Donor Name"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
              <v-col class="px-4" cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="donorEmail"
                  label="Donor Email"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-if="
                this.type === 'add' ||
                (this.type === 'edit' &&
                  (this.mode === 'CASH' || this.mode === 'CHECK'))
              "
            >
              <v-col class="px-4" cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="donationAmount"
                  label="Donation Amount"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="px-4" cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                  open-on-focus
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="formatedDate"
                      prepend-inner-icon="mdi-calendar-range"
                      label="Donation Date"
                      v-bind="attrs"
                      readonly
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="donationDate"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="px-4" cols="12">
                <v-textarea
                  outlined
                  dense
                  v-model="additionalInfomation"
                  label="Any additional Information"
                  color="#7253CF"
                  class="formFields"
                  row-height="8"
                  height="80px"
                  :disabled="formLoading"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-if="
                this.type === 'add' ||
                (this.type === 'edit' &&
                  (this.mode === 'CASH' || this.mode === 'CHECK'))
              "
            >
              <v-col class="px-4" cols="12">
                <v-textarea
                  outlined
                  dense
                  v-model="messageByDonor"
                  label="Message By Donor"
                  color="#7253CF"
                  class="formFields"
                  row-height="8"
                  height="80px"
                  :disabled="formLoading"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardAction pb-5 px-8 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-7 mt-5 mr-2"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Add donation</span>
          </v-btn>
          <v-btn
            :loading="formLoading"
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-7 mt-5 mr-2"
            dark
            color="#38227A"
            @click="submitForm"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_ADMIN_DONATION_GET,
  API_ADMIN_DONATION_POST,
  API_ADMIN_DONATION_PATCH,
  API_ADMIN_GET_SCHOOL_LIST,
  API_ADMIN_GET_DISTRICT_LIST,
  // API_ADMIN_TEACHER_MANAGEMENT_LIST,
  API_GET_TEACHER_LIST,
  // API_ADMIN_STUDENT_MANAGEMENT_LIST,
  API_GET_STUDENT_LIST,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
import authToken from "@/common/authToken";
export default {
  name: "AddEditDonationModal",
  data() {
    return {
      dateFormat: "MM-DD-YYYY",
      rules: RULES,
      menu: false,
      loading: false,
      formLoading: false,
      donationFor: "student",
      additionalInfomation: "",
      school: null,
      schoolList: [],
      donationForList: ["student", "teacher", "school", "district"],
      donationForListSchool: ["student", "teacher", "school"],
      payment_mode: "Cash",
      donorName: "",
      donorEmail: "",
      donationAmount: null,
      donationDate: "",
      messageByDonor: "",
      onlineDonationDetail: [],
      district: null,
      districtList: [],
      teacher: null,
      teacherList: [],
      student: null,
      studentList: [],
      camparisionData: {},
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "donationManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    formatedDate: {
      get() {
        if (this.donationDate) {
          return moment(this.donationDate).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set(newDate) {
        this.donationDate = newDate;
        return moment(this.donationDate).format("MM-DD-YYYY");
      },
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewDonationModal({ show: value });
      },
    },
    type() {
      return this.$store.state.donationManagement.addEditViewModal.type;
    },
    mode() {
      return this.$store.state.donationManagement.addEditViewModal.donationMode;
    },
    toastMessage() {
      if (this.mode === "CASH" || this.mode === "CHECK") {
        if (this.type === "add") {
          return "Donation Detail Added";
        } else {
          return "Donation Detail Updated";
        }
      } else {
        return "Additional Information Added";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add new Donation";
        case "edit":
          switch (this.mode) {
            case "ONLINE":
              return "Edit Online Donation";
            case "CASH":
              return "Edit Offline Donation";
            case "CHECK":
              return "Edit Offline Donation";
            default:
              return "";
          }
        default:
          return "";
      }
    },
    donationForConditionalList() {
      if (this.layout === "AdminDashboardLayout") {
        return this.donationForList;
      } else {
        if (this.$route.matched[0].path.substring(1) === "district") {
          return this.donationForList;
        } else {
          return this.donationForListSchool;
        }
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
    donationFor: function () {
      if (this.donationFor === "student") {
        this.getStudentList();
      } else if (this.donationFor === "district") {
        this.getDistrictList();
      } else if (this.donationFor === "school") {
        this.getSchoolList();
      } else {
        this.getTeacherList();
      }
    },
  },
  methods: {
    ...mapActions({
      // donation Modal
      toggleAddEditViewDonationModal: "donationManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    getFieldText(item) {
      return `${item.student_first_name}  ${item.student_last_name}`;
    },
    openCallBackModal() {
      if (this.type === "edit") {
        this.getDonationDetail();
        this.getStudentList();
      } else {
        this.getStudentList();
        console.log("get student djdjdfjkfj");
      }
    },
    closeCallBackModal() {
      this.additionalInfomation = "";
      this.school = null;
      this.schoolList = [];
      this.donationFor = "student";
      this.payment_mode = "Cash";
      this.donorName = "";
      this.donorEmail = "";
      this.donationAmount = null;
      this.donationDate = "";
      this.messageByDonor = "";
      this.onlineDonationDetail = [];
      this.district = null;
      this.student = null;
      this.teacher = null;
      this.districtList = [];
      this.studentList = [];
      this.teacherList = [];
      this.camparisionData = {};
    },
    getSchoolList() {
      const successHandler = (res) => {
        console.log(res.data);
        const data = res.data;
        this.schoolList = data.school_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      if (this.layout === "CampaignDashboardLayout") {
        let PANEL = this.$route.matched[0].path.substring(1);
        if (PANEL === "district") {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "admin") {
            formData["district_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        } else {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
          this.school = this.selectedCampaignData.organizationData.id;
          if (this.userTeam === "district" || this.userTeam === "admin") {
            formData["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
      } else {
        formData["donation_id"] =
          this.$store.state.donationManagement.addEditViewModal.donationID;
      }
      console.log(formData, "formData");
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getDistrictList() {
      const successHandler = (res) => {
        console.log(res.data);
        this.districtList = res.data.district_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      if (this.layout === "CampaignDashboardLayout") {
        let PANEL = this.$route.matched[0].path.substring(1);
        if (PANEL === "district") {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "admin") {
            formData["district_id"] =
              this.selectedCampaignData.organizationData.id;
          }
          this.district = this.selectedCampaignData.organizationData.id;
        } else {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "district" || this.userTeam === "admin") {
            formData["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
      } else {
        formData["donation_id"] =
          this.$store.state.donationManagement.addEditViewModal.donationID;
      }
      Axios.request_GET(
        API_ADMIN_GET_DISTRICT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getTeacherList() {
      const successHandler = (res) => {
        console.log(res.data);
        this.teacherList = res.data.teacher_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res, "failed");
        this.loading = false;
      };
      let dataTableParams = {};
      if (this.layout === "CampaignDashboardLayout") {
        let PANEL = this.$route.matched[0].path.substring(1);
        if (PANEL === "district") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "admin") {
            dataTableParams["district_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        } else {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "district" || this.userTeam === "admin") {
            dataTableParams["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
      } else {
        dataTableParams["donation_id"] =
          this.$store.state.donationManagement.addEditViewModal.donationID;
      }
      console.log(dataTableParams);
      Axios.request_GET(
        API_GET_TEACHER_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getStudentList() {
      const successHandler = (res) => {
        console.log(res.data);
        this.studentList = res.data.student_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res, "failed");
        this.loading = false;
      };
      let dataTableParams = {};
      if (this.layout === "CampaignDashboardLayout") {
        let PANEL = this.$route.matched[0].path.substring(1);
        if (PANEL === "district") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "admin") {
            dataTableParams["district_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        } else {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "district" || this.userTeam === "admin") {
            dataTableParams["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
      } else {
        dataTableParams["donation_id"] =
          this.$store.state.donationManagement.addEditViewModal.donationID;
      }
      Axios.request_GET(
        API_GET_STUDENT_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getDonationDetail() {
      const self = this;

      self.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.onlineDonationDetail = res.data.donation_detail;

        this.payment_mode = res.data.donation_detail.payment_mode;
        this.donationFor = res.data.donation_detail.donation_for;
        this.donorName = res.data.donation_detail.donor_name;
        this.donorEmail = res.data.donation_detail.donor_email;
        this.donationAmount = res.data.donation_detail.donated_amount;
        this.donationDate = moment(
          res.data.donation_detail.donation_date
        ).format("YYYY-MM-DD");

        this.additionalInfomation = res.data.donation_detail.additional_info;
        this.messageByDonor = res.data.donation_detail.donor_special_message;
        this.district = res.data.donation_detail.district;
        this.school = res.data.donation_detail.school;
        this.teacher = res.data.donation_detail.teacher;
        this.student = res.data.donation_detail.student;

        this.camparisionData = res.data.donation_detail;
        console.log(this.camparisionData);
        self.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      let formData = {};
      formData["donation_id"] =
        this.$store.state.donationManagement.addEditViewModal.donationID;
      Axios.request_GET(
        API_ADMIN_DONATION_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      const self = this;
      self.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        self.formLoading = false;
        self.toggleAddEditViewDonationModal({ show: false });
        this.$emit("reload");
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res);
        self.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["additional_info"] = this.additionalInfomation;
      if (this.type == "add") {
        formData[""];
        formData["donation_for"] = this.donationFor;
        if (this.donationFor === "district") {
          formData["district"] = this.district;
        } else if (this.donationFor === "school") {
          formData["school"] = this.school;
        } else if (this.donationFor === "teacher") {
          formData["teacher"] = this.teacher;
        } else {
          formData["student"] = this.student;
        }
        formData["payment_mode"] = this.payment_mode;
        formData["donor_name"] = this.donorName;
        formData["donor_email"] = this.donorEmail;
        formData["amount"] = this.donationAmount;
        if (this.donationDate) {
          formData["donation_date"] = moment(this.donationDate).format(
            "MM-DD-YYYY"
          );
        }
        formData["donor_special_message"] = this.messageByDonor;
      } else {
        //filtering in edit
        if (this.camparisionData.donation_for === this.donationFor) {
          if (this.donationFor === "district") {
            if (this.district !== this.camparisionData.district) {
              formData["district"] = this.district;
            }
          } else if (this.donationFor === "student") {
            if (this.student !== this.camparisionData.student) {
              formData["student"] = this.student;
            } else if (this.donationFor === "teacher") {
              if (this.teacher !== this.camparisionData.teacher) {
                formData["teacher"] = this.teacher;
              }
            } else {
              if (this.school !== this.camparisionData.school) {
                formData["school"] = this.school;
              }
            }
          }
        } else {
          formData["donation_for"] = this.donationFor;
          if (this.donationFor === "district") {
            formData["district"] = this.district;
          } else if (this.donationFor === "school") {
            formData["school"] = this.school;
          } else if (this.donationFor === "teacher") {
            formData["teacher"] = this.teacher;
          } else {
            formData["student"] = this.student;
          }
          //cash and cheque
        }
        if (
          this.type === "add" ||
          (this.type === "edit" &&
            (this.mode === "CASH" || this.mode == "CHECK"))
        ) {
          formData["payment_mode"] = this.payment_mode;
          formData["donor_name"] = this.donorName;
          formData["donor_email"] = this.donorEmail;

          if (this.donationAmount !== this.camparisionData.amount) {
            formData["amount"] = this.donationAmount;
          }
          if (this.donationDate) {
            formData["donation_date"] = moment(this.donationDate).format(
              "MM-DD-YYYY"
            );
          }
          formData["donor_special_message"] = this.messageByDonor;
        }
      }
      if (this.type === "edit") {
        formData["donation_id"] =
          this.$store.state.donationManagement.addEditViewModal.donationID;
      }
      if (this.layout === "CampaignDashboardLayout") {
        formData["campaign"] = this.selectedCampaignData.campaignData.id;
      }
      console.log(formData, "formData");
      if (this.type === "add") {
        Axios.request_POST(
          API_ADMIN_DONATION_POST,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
      if (this.type === "edit") {
        Axios.request_PATCH(
          API_ADMIN_DONATION_PATCH,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */
  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.formTitle {
  font-size: 14px;
  color: #38227a;
  font-weight: 400;
  padding-bottom: 2px;
}
.formValue {
  font-size: 17px;
  color: #1d1d1d;
}
</style>
